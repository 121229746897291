export type AbortablePromise<T> = {
  promise: Promise<T>;
  abort: () => void;
};

export function pCancelable<T>(promise: Promise<T>): AbortablePromise<T> {
  let aborted = false;
  const abort = () => (aborted = true);

  const abortablePromise = new Promise<T>((resolve, reject) => {
    promise.then(res => {
      if (!aborted) {
        resolve(res);
      } else {
        reject('Promise has been canceled');
      }
    });

    promise.catch(reject);
  });

  return { promise: abortablePromise, abort };
}

type AsyncFun<T> = () => Promise<T>;

export function asyncFnCancelable<T>(asyncFn: AsyncFun<T>, signal: AbortSignal): Promise<T> {
  const promise = asyncFn();

  const abortablePromise = new Promise<T>((resolve, reject) => {
    signal.addEventListener('abort', reject);

    promise
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });

  return abortablePromise;
}
