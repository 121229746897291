import { useSessionStorage } from '@/composables/useSessionStorage';
import { useStore } from 'vuex';
import { SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { computed, ref } from 'vue';

export const wrongStepIndex = ref(0);

const getUrlParams = (fullUrl: string) => {
  const urlParamsStartPosition = fullUrl?.indexOf('?') || -1;
  if (urlParamsStartPosition !== -1) {
    return new URLSearchParams(fullUrl.slice(urlParamsStartPosition));
  }
  return new URLSearchParams();
};
export const isRefNetworkSupported = computed(
  () =>
    getUrlParams(useSessionStorage().getItem('ref_link') as string).get('network') ===
    SELECTED_NETWORK_NAME,
);
export const isRefLinkChanged = (fullPath: string) => {
  const network = getUrlParams(fullPath).get('network');
  const { state } = useStore();

  if (!network) {
    if (!isRefNetworkSupported.value) useSessionStorage().dispatchStorageEvent('ref_link');
    return;
  }
  if (
    !useSessionStorage().getItem('ref_link') ||
    (useSessionStorage().getItem('ref_link') as string) !== fullPath ||
    !state.wallet.isNetworkSupported ||
    !isRefNetworkSupported.value
  ) {
    useSessionStorage().setItem('ref_link', fullPath);
    useSessionStorage().dispatchStorageEvent('ref_link');
  }
};
