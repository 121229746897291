<template>
  <div class="flex flex-column content-error-page">
    <Icon name="dangerOutline" size="160" class="align-self-center" />
    <div class="mt-s6 h3">
      {{ $t('error.network.selectedNetwork') }}
      <span v-if="getWalletNetwork">({{ getWalletNetwork.shortName }})</span>
      {{ $t('error.network.notSupported') }}
    </div>
    <div class="mt-s2 text-muted">
      <span v-if="isManySupportedNetworks">
        {{ $t('error.network.supportedNetworks') }}: {{ getSupportedNetworksNames }}.
      </span>
      <span v-else>
        {{ $t('error.network.supportedNetwork') }} {{ getSupportedNetworksNames }}.
      </span>
    </div>
    <Button class="button-primary mt-s5 switch-net" @click="onSwitchNetwork">
      {{ $t('switchNetwork') }}
    </Button>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon';
import { mapGetters } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { SUPPORTED_NETWORK_IDS, switchNetwork } from '@/utils/blockchain.ts';

export default {
  name: 'NetworkNotSupported',
  components: { Icon },
  methods: {
    async onSwitchNetwork() {
      switchNetwork(this.getFirstSupportedNetwork);
    },
  },
  computed: {
    ...mapGetters(MODULE_NAMES.WALLET, [
      'getWalletNetwork',
      'getSupportedNetworksNames',
      'getFirstSupportedNetwork',
    ]),
    isManySupportedNetworks() {
      return SUPPORTED_NETWORK_IDS?.length > 1;
    },
  },
};
</script>

<style scoped lang="scss">
.content-error-page {
  width: 360px;
  max-width: 100%;
}
.switch-net {
  width: fit-content;
  align-self: center;
}
</style>
