<template>
  <div
    class="flex w-full min-h-screen flex-column justify-content-between layout"
    :class="`layout-${$store.state.layout.viewMode} ${bgClass()}`"
  >
    <Head>
      <meta property="twitter:card" name="twitter:card" :content="SEO_META_TWITTER.TWITTER_CARD" />
      <meta
        property="twitter:title"
        name="twitter:title"
        :content="SEO_META_TWITTER.TWITTER_TITLE"
      />
      <meta
        property="twitter:description"
        name="twitter:description"
        :content="SEO_META_TWITTER.TWITTER_DESCRIPTION"
      />
      <meta
        property="twitter:image"
        name="twitter:image"
        :content="SEO_META_TWITTER.PREVIEW_IMAGE"
      />
      <meta property="og:title" name="og:title" :content="SEO_META_OG.OG_TITLE" />
      <meta property="og:site_name" name="og:site_name" :content="SEO_META_OG.OG_SITE_NAME" />
      <meta property="og:type" name="og:type" :content="SEO_META_OG.OG_TYPE" />
      <meta property="og:description" name="og:description" :content="SEO_META_OG.OG_DESCRIPTION" />
      <meta property="og:image" name="og:image" :content="SEO_META_OG.OG_IMAGE" />
    </Head>
    <Loader v-if="isLoadingUi"></Loader>
    <template v-else>
      <div
        class="flex w-full mx-auto content flex-grow-1 flex-column"
        :class="$store.state.layout.viewMode"
      >
        <NetworkNotSupported v-if="$store.state.wallet.isNetworkSupported === false" />
        <div class="flex flex-column mb-s14" v-else>
          <router-view />
        </div>
      </div>
      <Footer />
      <Notifications />
      <ConfirmDialog />
      <Header />
      <ReferralBanners v-if="getIsRefProgram()" />
    </template>
  </div>
</template>

<script>
import Header from './header/Header.vue';
import Footer from './footer/Footer.vue';
import { MODULE_NAMES } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import { LAYOUT_ACTION_TYPES } from '@/store/modules/layout.module';
import Loader from '../../components/Loader';
import Notifications from './notifications/Notifications';
import NetworkNotSupported from './error/NetworkNotSupported';
import { SEO_META_OG, SEO_META_TWITTER } from '@/constants/seo';
import { Head } from '@vueuse/head';
import ReferralBanners from '@/views/layout/referral-program/referral-banners/ReferralBanners';
import { useReferral } from '@/store/modules/referral/useReferral';
import { mapStores } from 'pinia';

export default {
  name: 'Layout',
  components: {
    ReferralBanners,
    NetworkNotSupported,
    Notifications,
    Loader,
    Footer,
    Header,
    Head,
  },
  data() {
    return {
      isLoad: true,
    };
  },
  computed: {
    ...mapGetters(MODULE_NAMES.LAYOUT, ['isMobile', 'isLoadingUi']),
    SEO_META_OG() {
      return SEO_META_OG;
    },
    SEO_META_TWITTER() {
      return SEO_META_TWITTER;
    },
    ...mapStores(useReferral),
  },
  methods: {
    setLayout() {
      const cls = ['mobile', 'tablet', 'desktop'];
      if (window.innerWidth < 576) {
        this.setMobile();
      } else if (window.innerWidth >= 576 && window.innerWidth < 1200) {
        this.setTablet();
      } else {
        this.setDesktop();
      }
      document.body.classList.remove(...cls);
      document.body.classList.add(this.$store.state.layout.viewMode);
    },
    ...mapActions(MODULE_NAMES.LAYOUT, {
      setMobile: LAYOUT_ACTION_TYPES.SET_MOBILE,
      setTablet: LAYOUT_ACTION_TYPES.SET_TABLET,
      setDesktop: LAYOUT_ACTION_TYPES.SET_DESKTOP,
    }),
    bgClass() {
      return this.isLoadingUi ? 'loading' : `${this.$route.name.toString().toLowerCase()}-page`;
    },
    getIsRefProgram() {
      return this.referralStore.isRefProgram;
    },
  },
  beforeMount() {
    this.setLayout();
  },
  mounted() {
    window.addEventListener('resize', this.setLayout);
  },
};
</script>

<style scoped></style>
