<template>
  <div class="footer-info-blocks sm:w-23rem xl:w-auto">
    <div class="footer-info-block">
      <div class="sm:w-6rem xl:w-auto">
        <div class="mb-s3 xl:mb-s4 caption uppercase">{{ $t('LQFprice') }}</div>
        <Skeleton v-if="!bluesPrice" class="w-5"></Skeleton>
        <div v-else class="white-space-nowrap">{{ '$' + format(bluesPrice.toFixed()) }}</div>
      </div>
    </div>

    <div class="footer-info-block">
      <div class="sm:w-9rem xl:w-auto">
        <div class="mb-s3 xl:mb-s4 caption uppercase">{{ $t('totalSupply') }}</div>
        <div class="white-space-nowrap">{{ totalBluesSupply }} BLUES</div>
      </div>
    </div>

    <div class="footer-info-block">
      <div class="sm:w-6rem xl:w-auto">
        <div class="mb-s3 xl:mb-s4 caption uppercase">{{ $t('tvl') }}</div>
        <Skeleton v-if="!tvlAmount" class="w-full"></Skeleton>
        <div v-else class="white-space-nowrap">{{ format(tvlAmount) }}</div>
      </div>
    </div>

    <div class="footer-info-block" v-if="contractAddress">
      <div class="sm:w-9rem xl:w-auto">
        <div class="mb-s3 xl:mb-s4 caption uppercase">{{ $t('contract') }}</div>
        <div class="flex flex-column">
          <div class="flex align-items-center mb-s2">
            <span class="mr-s2">{{ addressForPrint(contractAddress) }}</span>
            <CopyIcon :data="contractAddress" class="text-gray" />
          </div>
          <ExternalLink :label="$t('viewInExplorer')" :url="externalLink" class="text-primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalLink from '@/components/ExternalLink';
import CopyIcon from '@/components/icon/CopyIcon';
import { TOTAL_BLUES_SUPPLY, ChainId } from '@/sdk/constants';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import {
  SELECTED_NETWORK_NAME,
  CURRENT_NATIVE_TOKEN_ADDRESS,
} from '@/helpers/networkParams.helper';
import { getScanLink } from '@/sdk/utils';
import { MODULE_NAMES } from '@/store';
import { mapActions } from 'vuex';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import { asyncFnCancelable } from '@/utils/promise';
import { addressForPrint } from '@/composables/utils/useUtils';

export default {
  name: 'InfoPanel',
  components: { ExternalLink, CopyIcon },
  mixins: [formatNumbersMixin],
  data() {
    return {
      contractAddress: CURRENT_NATIVE_TOKEN_ADDRESS,
      updateController: new AbortController(),
      updateInterval: null,
    };
  },
  async created() {
    try {
      await asyncFnCancelable(
        () => Promise.all([this.getBluesPrice(), this.getTvlAmount()]),
        this.updateController.signal,
      );
      this.updateInterval = setInterval(() => {
        this.getBluesPrice();
        this.getTvlAmount();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  beforeUnmount() {
    this.updateController.abort();
    this.updateController = null;

    clearInterval(this.updateInterval);
    this.updateInterval = null;
  },
  methods: {
    addressForPrint,
    ...mapActions(MODULE_NAMES.FARMING, {
      getBluesPrice: FARMING_ACTION_TYPES.GET_BLUES_PRICE,
      getTvlAmount: FARMING_ACTION_TYPES.GET_CURRENT_TOTAL_TVL,
    }),
  },
  computed: {
    bluesPrice() {
      return this.$store.state.farming.bluesPrice;
    },
    totalBluesSupply() {
      return this.format(TOTAL_BLUES_SUPPLY, 11);
    },
    externalLink() {
      return getScanLink(
        this.contractAddress,
        'tokenTransfers',
        ChainId[`MILKOMEDA_${SELECTED_NETWORK_NAME.toUpperCase()}_${SUPPORTED_NETWORK_MODE}`],
      );
    },
    tvlAmount() {
      return this.$store.state.farming.tvlAmount;
    },
  },
};
</script>

<style scoped lang="scss">
.footer-info-blocks {
  font-size: $font-size-sm;
  display: flex;
  white-space: nowrap;
  .desktop & {
    flex-wrap: nowrap;
    margin-top: -60px;
    margin-left: -60px;
    justify-content: flex-end;
    .footer-info-block {
      padding-top: 60px;
      padding-left: 60px;
    }
  }
  .tablet & {
    max-width: 32rem;
    flex-wrap: wrap;
    margin-top: -40px;
    margin-left: -60px;
    justify-content: flex-start;

    .footer-info-block {
      padding-top: 40px;
      padding-left: 60px;
    }
  }
  .mobile & {
    flex-wrap: wrap;
    margin-top: -36px;
    justify-content: space-between;
    .footer-info-block {
      width: 40%;
      padding-top: 36px;
    }
  }
}
.link {
  &:hover {
    text-decoration: underline;
  }
}
</style>
