import { ref, Ref } from 'vue';

export function useToggle(defaultValue = false): {
  toggleValue: Ref<boolean>;
  setTrue: () => void;
  setFalse: () => void;
} {
  const toggleValue = ref(defaultValue);

  const setTrue = () => (toggleValue.value = true);
  const setFalse = () => (toggleValue.value = false);

  return {
    toggleValue,
    setTrue,
    setFalse,
  };
}
