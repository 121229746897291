<template>
  <div class="w-full h-full flex flex-column flex-grow-1 align-items-center justify-content-center">
    <div class="w-5rem h-5rem flex">
      <svg id="shadow" width="70" height="70" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6297 43.0531C51.5262 46.5896 55.3226 49.5686 59.0466 51.7474V54.5545V59.4915C53.427 56.8629 48.0288 52.7344 42.8565 48.0401C37.6302 43.2967 32.462 37.8127 27.4092 32.437L27.1058 32.1141C22.1192 26.8084 17.2436 21.621 12.3664 17.1309C8.51181 13.5824 4.75916 10.5681 1.08053 8.32822V0.508475C6.65337 3.23062 12.0205 7.43113 17.1758 12.1771C22.2401 16.8393 27.2645 22.1854 32.1883 27.4245C32.3168 27.5612 32.4452 27.6978 32.5735 27.8343C37.6599 33.2459 42.6415 38.5258 47.6297 43.0531Z" fill="#F2F2F2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 53.517C43.0564 53.517 53.5805 43.0564 53.5805 30C53.5805 16.9436 43.0564 6.48305 30 6.48305C16.9436 6.48305 6.48305 16.9436 6.48305 30C6.48305 43.0564 16.9436 53.517 30 53.517ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#F2F2F2"/>
      </svg>
      <svg id="loader" width="70" height="70" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6297 43.0531C51.5262 46.5896 55.3226 49.5686 59.0466 51.7474V54.5545V59.4915C53.427 56.8629 48.0288 52.7344 42.8565 48.0401C37.6302 43.2967 32.462 37.8127 27.4092 32.437L27.1058 32.1141C22.1192 26.8084 17.2436 21.621 12.3664 17.1309C8.51181 13.5824 4.75916 10.5681 1.08053 8.32822V0.508475C6.65337 3.23062 12.0205 7.43113 17.1758 12.1771C22.2401 16.8393 27.2645 22.1854 32.1883 27.4245C32.3168 27.5612 32.4452 27.6978 32.5735 27.8343C37.6599 33.2459 42.6415 38.5258 47.6297 43.0531Z" fill="#F2F2F2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 53.517C43.0564 53.517 53.5805 43.0564 53.5805 30C53.5805 16.9436 43.0564 6.48305 30 6.48305C16.9436 6.48305 6.48305 16.9436 6.48305 30C6.48305 43.0564 16.9436 53.517 30 53.517ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#F2F2F2"/>
      </svg>
    </div>
    <div class="text-center mt-s6 text-xl">
      {{ $t('loading.liquifi') }}<br />
      {{ $t('loading.wait') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped lang="scss">
#shadow {
  fill: var(--text-color);
  opacity: 0.2;
  animation: animate_opacity 1.2s infinite;
}
#loader {
  fill: var(--text-color);
  position: absolute;
  animation: animate_pulse 1.2s infinite;
}
@keyframes animate_pulse {
  0% {
    transform: scale3d(1, 1, 1);
    animation-timing-function: ease-in;
  }
  25% {
    transform: scale3d(1.3, 1.3, 1.3);
    animation-timing-function: ease-out;
  }
  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
    animation-timing-function: ease-in;
  }
}
@keyframes animate_opacity {
  0% {
    opacity: 0.2;
  }
  25% {
    transform: scale3d(1.3, 1.3, 1.3);
    animation-timing-function: ease-out;
  }
  100% {
    opacity: 0;
    transform: scale3d(5, 5, 5);
    animation-timing-function: ease-in;
  }
}
</style>
